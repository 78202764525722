@import url('https://fonts.googleapis.com/css?family=Inter');

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;

  height: 0;
  left: 28%;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}


ul.hor {
  list-style: none;
  display: flex;
}

ul.hor > li {
  padding: 15px;
  color: aquamarine;
}

.headerContent {
  display: flex;
  padding-top: 70px;
  padding-left: 20px;
}

.main {
  justify-content: center;
}

div.mainPage {
  background-color: red;
  background-size: cover;
  font-family: 'Inter', sans-serif;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

div.title-flexbox {
  display: flex;
  padding: 5px;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  color: aliceblue;
}

img.nav-logo {
  width: 50px;
  height: fit-content;
  align-self: center;
}

nav.flexbox {
  display: flex;
  justify-content: space-between;
  background-color: rgb(68, 68, 132);
  opacity: 1;
  width: 100%;
  margin: 0;
  position: fixed;
  left: 0;
  top: 0;
  align-items: center;
  padding-bottom: 0px;
}



footer.footer {
  position : fixed;
  left : 0;
  bottom : 0;
  padding: 10px;
  background-color: lightslategrey;
  color: aliceblue;
  width: 100%;
  text-align: center;
}